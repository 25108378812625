import { DialogProps } from '@/components/Reviews/Detail/Dialog'
import useLoginRedirect from '@/hooks/useLoginRedirect'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { productAddedToCart } from '@/lib/mixpanels/mixpanel'
import { PriceSectionProps, PurchaseStatus } from '@/modules/products/components/PriceSection'
import { LIBRARIES_INDEX_PAGE, SHOP_CART_PAGE } from '@/settings/constant'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, isAxiosError } from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { BooksCartType, getCurrentCartQuery, postBooksCartItem } from 'src/api/booksCart'
import { isBooksProduct } from 'src/api/booksProducts'
import { IProduct } from 'src/api/product'
import useHandoutProductStore from '../../../store/relatedProduct'

type OpenModalProps = Partial<DialogProps>

const useAddCart = ({ product }: PriceSectionProps) => {
	const { isLoggedIn } = useAuthStore()
	const { redirectToLogin } = useLoginRedirect()

	const [isModal, setIsModal] = useState(false)
	const [modalProps, setModalProps] = useState<OpenModalProps>({
		title: '다시 시도해 주세요.',
		severity: 'caution',
	})

	// const productType = isBooksProduct(product) ? product.productType : product.source_type
	const queryClient = useQueryClient()
	const { purchaseStatus, statusMessage } = useMemo(() => {
		let status: PurchaseStatus = PurchaseStatus.normal

		const isActive = (isBooksProduct(product) ? 'active' : product.status) === 'active'
		const canPurchase = isBooksProduct(product) ? false : product?.flags?.is_possible_purchase

		if (!isActive) {
			status = PurchaseStatus.inActive
		} else if (isBooksProduct(product)) {
			status = product.available ? PurchaseStatus.normal : PurchaseStatus.noLicense
		} else if ((product as IProduct).source_type === 'handout' && !canPurchase) {
			status = isLoggedIn ? PurchaseStatus.noLicense : PurchaseStatus.unknownLicense
		}

		return {
			purchaseStatus: status,
			statusMessage:
				status === PurchaseStatus.unknownLicense
					? '구매제한이 있는 상품입니다.\n 로그인후 확인해주세요.'
					: status !== PurchaseStatus.normal
					? '판매 중단된 상품입니다.'
					: `장바구니 담기`,
		}
	}, [product, isLoggedIn])

	const licensePrice = useCallback(() => {
		if (isBooksProduct(product)) {
			return {
				originalPrice: product.originPrice,
				price: product.price,
				id: product.id,
			}
		} else {
			return { price: product.price, id: product.id }
		}
	}, [product])

	const { refetch } = getCurrentCartQuery({
		enabled: isLoggedIn,
		params: {
			cartType: 'separate',
		},
	})

	const { mutate: addItemToCart } = useMutation({
		mutationFn: postBooksCartItem,
		onSuccess: async (data) => {
			const { hasAlreadyOwned, purchaseProductsInfo } = useHandoutProductStore.getState()
			let title = '장바구니에 상품 1개를 담았어요.'
			let content = '이미 장바구니에 담겨있거나 이용 중인 상품은 제외되어 담겨요.'
			if (purchaseProductsInfo.selectedRelatedProducts.length > 0) {
				let count = purchaseProductsInfo.selectedRelatedProducts.length + 1
				if (hasAlreadyOwned) {
					count = purchaseProductsInfo.selectedRelatedProducts.length
				}
				title = `장바구니에 상품 ${count}개를 담았어요.`
			}
			setModalProps({
				title: title,
				content: content,
				severity: 'success',
				suffix: {
					variant: 'solid',
					label: '장바구니 이동',
					handleClick: () => {
						window.location.href = SHOP_CART_PAGE
					},
				},
				prefix: {
					variant: 'soft',
					label: '닫기',
					handleClick: () => {
						setIsModal(false)
					},
				},
			})
			setIsModal(true)
			//mixpanel
			productAddedToCart({
				...product,
				id: product.id,
				source_type: isBooksProduct(product) ? product.provider : product.source_type,
				source: isBooksProduct(product) ? null : product.source,
				relativeProducts: [
					...purchaseProductsInfo.selectedRelatedProducts.map((product) => product.id),
				],
			})

			refetch()
		},
		onError: (error: AxiosError) => {
			console.error(error)
			if (isAxiosError(error)) {
				const {
					response: { status, data },
				} = error
				//여기서 이제 에러처리
				console.log('error', data.message)
				if (status === 409 && data.message.includes('Found already has owned products')) {
					//current product인 경우
					setModalProps({
						title: '이미 이용 중이에요.',
						content: '해당 자료는 라이브러리에서 확인할 수 있어요.',
						suffix: {
							variant: 'solid',
							label: '자료 보관함 이동',
							handleClick: () => {
								window.location.href = LIBRARIES_INDEX_PAGE
							},
						},
					})
					setIsModal(true)
				}
				if (status === 409 && data.message === 'Already added') {
					setModalProps({
						title: '이미 담은 상품이에요.',
						content: '상품 구매는 장바구니를 통해서 가능해요.',
						severity: 'caution',
						suffix: {
							variant: 'solid',
							label: '장바구니 이동',
							handleClick: () => {
								window.location.href = SHOP_CART_PAGE
							},
						},
					})
					setIsModal(true)
				} else if (status === 409 && data.message === 'Duplicate license') {
					setModalProps({
						title: '이미 이용 중인 자료에요.',
						content: '해당 자료는 라이브러리에서 확인할 수 있어요.',
						suffix: {
							variant: 'solid',
							label: '자료 보관함 이동',
							handleClick: () => {
								window.location.href = LIBRARIES_INDEX_PAGE
							},
						},
					})
					setIsModal(true)
				}
			}
		},
	})
	const handleAddCartClick = useCallback(() => {
		const { purchaseProductsInfo, currentProduct, closePassSectionModal, hasAlreadyOwned } =
			useHandoutProductStore.getState()
		closePassSectionModal()
		if (!isLoggedIn) {
			redirectToLogin()
			return
		}
		const productType = isBooksProduct(product) ? product.productType : product.source_type
		if (productType === 'handout') {
			//연관교재 조회 및 연관교재 함께구매
			const { selectedRelatedProducts } = purchaseProductsInfo
			if (selectedRelatedProducts.length === 0 && hasAlreadyOwned) {
				setModalProps({
					title: '이미 이용중인 자료에요.',
					content: '해당 자료는 라이브러리에서 확인할 수 있어요.',
					suffix: {
						variant: 'solid',
						label: '자료 보관함 이동',
						handleClick: () => {
							window.location.href = LIBRARIES_INDEX_PAGE
						},
					},
				})
				setIsModal(true)
				return
			}
			const cartItems = hasAlreadyOwned
				? selectedRelatedProducts.map((product) => ({
						productId: `${product.id}`,
				  }))
				: selectedRelatedProducts
						.map((product) => ({
							productId: `${product.id}`,
						}))
						.concat({
							productId: currentProduct.id.toString(),
						})

			addItemToCart({
				cartType: 'separate',
				cartItems,
			})
			return
		}
		addItemToCart({
			cartType: isBooksProduct(product) ? (product.productType as BooksCartType) : 'separate',
			cartItems: [
				{
					productId: `${product.id}`,
				},
			],
		})
	}, [isLoggedIn, redirectToLogin, product, addItemToCart, licensePrice])

	return {
		handleAddCartClick,
		isModal,
		setIsModal,
		modalProps,
		setModalProps,
		purchaseStatus,
		statusMessage,
	} as const
}

export default useAddCart
