import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { LOGIN_PAGE } from '@/settings/constant'

const HOME_PATH = '/'
const LOGIN_PATH = LOGIN_PAGE
export const REDIRECT_QUERY = 'redirectPath'
const useLoginRedirect = () => {
	const router = useRouter()
	const currentPathname = router.asPath
	const loginPath = `${LOGIN_PATH}?${REDIRECT_QUERY}=${currentPathname}`

	const redirectToLogin = useCallback(() => {
		router.push(`${LOGIN_PATH}?${REDIRECT_QUERY}=${currentPathname}`)
	}, [router, currentPathname])
	const redirectTo = useCallback(
		(path: string) => {
			router.push(`${path}?${REDIRECT_QUERY}=${currentPathname}`)
		},
		[router, currentPathname],
	)
	const redirectBack = useCallback(() => {
		if (router.query[REDIRECT_QUERY]) {
			router.replace(router.query[REDIRECT_QUERY] as string)
		} else {
			router.replace(HOME_PATH)
		}
	}, [router])

	const redirectAgain = useCallback(
		async (url: string, query?: object) => {
			const hasPath = !!router.query[REDIRECT_QUERY]

			if (hasPath) {
				await router.push({
					pathname: url,
					query: {
						...query,
						[REDIRECT_QUERY]: router.query[REDIRECT_QUERY],
					},
				})
			} else {
				await router.push(url)
			}
		},
		[router],
	)

	return { loginPath, redirectToLogin, redirectBack, redirectAgain, redirectTo }
}

export default useLoginRedirect
