import { useBooks } from '@/components/Books/Order'
import { AddToCartToast } from '@/components/Product/AddToCartToast'
import { calculateSaleRate } from '@/components/Product/CalculateSaleRate'
import Icon from '@/components/icon/Icon'
import { Cart, Sizes } from '@/components/icon/IconList'
import useLoginRedirect from '@/hooks/useLoginRedirect'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { productAddedToCart } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils/formatter'
import {
	BOOKS_ORDER,
	EBOOKS_ORDER,
	EBOOKS_UNIVERSITY_LANDING,
	LIBRARIES_INDEX_PAGE,
	SHOP_CART_PAGE,
} from '@/settings/constant'
import { ConfirmDialog } from '@bookips/solvook-ui-library'
import { useMutation } from '@tanstack/react-query'
import { Button } from 'antd'
import { isAxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useRef, useState } from 'react'
import { BooksCartType, getCurrentCartQuery, postBooksCartItem } from 'src/api/booksCart'
import { BooksProduct, isBooksProduct } from 'src/api/booksProducts'
import { IProduct } from 'src/api/product'
import { useModal } from 'src/utils/overlay'
import styled, { useTheme } from 'styled-components'
import { PriceInfo } from '../styles/ProductDetail.style'
import WarnBox from './ProductDetail/WarnBox'

export interface PriceSectionProps {
	type?: 'default' | 'side'
	product: IProduct | BooksProduct
}

export const enum PurchaseStatus {
	unknownLicense = 'unknownLicense',
	noLicense = 'noLicense',
	inActive = 'inActive',
	normal = 'normal',
}

export const getMillisecondsOfAddedModal = (product: PriceSectionProps['product']) =>
	1000 * (isBooksProduct(product) ? 5 : 2)

export const PriceSection = ({ type = 'default', product }: PriceSectionProps) => {
	const router = useRouter()
	const theme = useTheme()
	const { isBooks } = useBooks()
	const { isLoggedIn } = useAuthStore()
	const { redirectToLogin } = useLoginRedirect()
	const timer = useRef<NodeJS.Timeout>()
	const [isShowModal, setIsShowModal] = useState<boolean>(false)
	const originalPrice = isBooksProduct(product) ? numberFormatter(product.originPrice) : null
	const productType = isBooksProduct(product) ? product.productType : product.source_type
	const [addToCartCompleteModal, setAddToCartCompleteModal] = useState(false)
	const [
		isAlreadyPurchasedModal,
		{ open: openAlreadyPurchasedModal, close: closeAlreadyPurchasedModal },
	] = useModal('alreadyPurchased-modal')
	const [isAlreadyDialog, setIsAlreadyDialog] = useState(false)

	const { purchaseStatus, statusMessage } = useMemo(() => {
		let status: PurchaseStatus = PurchaseStatus.normal

		const isActive = (isBooksProduct(product) ? 'active' : product.status) === 'active'
		const canPurchase = isBooksProduct(product) ? false : product?.flags?.is_possible_purchase

		if (!isActive) {
			status = PurchaseStatus.inActive
		} else if (isBooksProduct(product)) {
			status = product.available ? PurchaseStatus.normal : PurchaseStatus.noLicense
		} else if ((product as IProduct).source_type === 'handout' && !canPurchase) {
			status = isLoggedIn ? PurchaseStatus.noLicense : PurchaseStatus.unknownLicense
		}

		return {
			purchaseStatus: status,
			statusMessage:
				status === PurchaseStatus.unknownLicense
					? '구매제한이 있는 상품입니다.\n 로그인후 확인해주세요.'
					: status !== PurchaseStatus.normal
					? '판매 중지된 상품입니다.'
					: `${
							productType === 'ebook'
								? 'eBook '
								: productType === 'aladinbook'
								? '도서 '
								: ''
					  }장바구니 담기`,
		}
	}, [product, isLoggedIn, productType])

	const showCompletedAddingItemToCart = useCallback(() => {
		clearTimeout(timer.current)
		setAddToCartCompleteModal(true)
		timer.current = setTimeout(
			() => setAddToCartCompleteModal(false),
			getMillisecondsOfAddedModal(product),
		)
	}, [product])

	const { refetch } = getCurrentCartQuery({
		enabled: isLoggedIn,
		params: {
			cartType: isBooksProduct(product) ? (product.productType as BooksCartType) : 'separate',
		},
	})
	const { mutate: addItemToCart } = useMutation({
		mutationFn: postBooksCartItem,
		onSuccess: async (data) => {
			showCompletedAddingItemToCart()
			productAddedToCart({
				...product,
				id: product.id,
				source_type: isBooksProduct(product) ? product.provider : product.source_type,
				source: isBooksProduct(product) ? null : product.source,
			})
			refetch()
		},
		onError: (error) => {
			if (isAxiosError(error)) {
				const {
					response: { status, data },
				} = error
				if (status === 409 && data.message === 'Already added') {
					showCompletedAddingItemToCart()
				} else if (data.message === 'Duplicate license') {
					openAlreadyPurchasedModal()
				}
			}
		},
	})
	const licensePrice = useCallback(() => {
		if (isBooksProduct(product)) {
			return {
				originalPrice: product.originPrice,
				price: product.price,
				id: product.id,
			}
		} else {
			return { price: product.price, id: product.id } // Fallback if no variant is found
		}
	}, [product])
	const price = numberFormatter(licensePrice().price)
	const onCart = useCallback(async () => {
		if (!isLoggedIn) {
			redirectToLogin()
			return
		}
		addItemToCart({
			cartType: isBooksProduct(product) ? (product.productType as BooksCartType) : 'separate',
			cartItems: [
				{
					productId: `${product.id}`,
				},
			],
		})
	}, [
		isLoggedIn,
		redirectToLogin,
		product,
		showCompletedAddingItemToCart,
		addItemToCart,
		licensePrice,
	])

	const handleClick = useCallback(() => {
		clearTimeout(timer.current)
		setIsShowModal(true)
		onCart()
		timer.current = setTimeout(
			() => setIsShowModal(false),
			getMillisecondsOfAddedModal(product) + 1000,
		)
	}, [onCart, product])

	// 대학생 이북인지 체크합니다. 이북에서만 할인율을 보여주지 않습니다.
	const isUniversityHome = useMemo(() => {
		if (router.pathname.includes(EBOOKS_UNIVERSITY_LANDING)) {
			return true
		}
		return false
	}, [router.pathname])

	return (
		<PriceSectionLayout>
			<PriceInfo>
				{originalPrice && originalPrice !== price && (
					<p className="original-price">
						{!isUniversityHome && (
							<span className="sale-rate">
								{calculateSaleRate(originalPrice, price)}
							</span>
						)}
						<span className="origin-price-text">{originalPrice}원</span>
					</p>
				)}
				<p className={'price'}>
					<span>판매가 </span>
					{price}원
				</p>
				<Button
					onClick={handleClick}
					className={'ctCartBtn'}
					size={'large'}
					type={'primary'}
					block
					disabled={purchaseStatus !== PurchaseStatus.normal}
					ghost>
					{statusMessage}
				</Button>
			</PriceInfo>
			<div style={{ position: 'relative' }}>
				{isShowModal &&
					(isBooksProduct(product) ? (
						<AddToCartToast
							addToCartCompleteModal={addToCartCompleteModal}
							type={type}
							link={isBooks ? BOOKS_ORDER : EBOOKS_ORDER}
							content={
								<>
									<Icon
										icon={Cart}
										size={Sizes.medium}
										color={theme.new.colors.white}
									/>
									{isBooks ? '도서' : 'eBook'} 장바구니에 담았어요
								</>
							}
						/>
					) : (
						<AddToCartToast
							type={type}
							content={
								<>
									<Icon
										icon={Cart}
										size={Sizes.medium}
										color={theme.new.colors.white}
									/>
									장바구니에 담았어요
								</>
							}
							link={SHOP_CART_PAGE}
							addToCartCompleteModal={addToCartCompleteModal}
						/>
					))}
			</div>
			<WarnBox productType={productType} />

			{/* 크롬 에러 buttonType 카멜케이스라 그런것 같은데 확인해보기... */}
			{/* <GenericPanelWithModal
				closeIcon
				open={isAlreadyPurchasedModal}
				title={<ModalTitle>이미 구매한 상품입니다</ModalTitle>}
				description={<>마이쏠북의 자료 보관함을 통해 상품을 확인할 수 있습니다.</>}
				okText={'자료 보관함 가기'}
				onOk={() => router.push(LIBRARIES_INDEX_PAGE)}
				cancelText={'닫기'}
				name={'alreadyPurchased-modal'}
				onClose={() => closeAlreadyPurchasedModal()}
			/> */}
			<ConfirmDialog
				open={isAlreadyDialog}
				severity="success"
				closeDialog={() => setIsAlreadyDialog(false)}
				title={'이미 구매한 상품입니다'}
				content={'마이쏠북의 자료 보관함을 통해 상품을 확인할 수 있습니다.'}
				okText={'자료 보관함 가기'}
				onOk={() => router.push(LIBRARIES_INDEX_PAGE)}
				cancelText={'닫기'}
			/>
		</PriceSectionLayout>
	)
}

const PriceSectionLayout = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	gap: 14px;
`
