import ExpertGNB from '@/components/common/GNB/ExpertGNB'
import GoodnotesGNB from '@/components/common/GNB/GoodnotesGNB'
import MarketGNB from '@/components/common/GNB/MarketGNB'
import { FooterView } from '@/containers/Layout/Footer/FooterView'
import { mobileTabBarHeight } from '@/containers/Layout/Header/MobileTabBar'
import useAuth from '@/hooks/useAuth'
import { useApiService } from '@/lib/apiService'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { redirect } from '@/lib/next-utils'
import { LogoutCommand } from '@/modules/accounts/commands/logoutCommand'
import { libraryLnbDimensions } from '@/modules/mypages/libraries/components/LibraryLNB'
import {
	ADMIN_PAGE_LOGIN,
	EBOOKS_UNIVERSITY_LANDING,
	HOME_PAGE,
	MYPAGE_STORES_PRODUCTS_NEW_INDEX,
	PUBLISHER_LOGIN,
	VIEWER_PAGE,
	VIEWER_PRINTOUT_PAGE,
} from '@/settings/constant'
import { Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { isInWebview } from 'src/utils/misc'
import styled from 'styled-components'
import { useSignout } from '../Header/LoginMenu'

const { Content } = Layout

const WebViewWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: white;

	.ant-layout-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	@media (min-width: 769px) and (max-width: 1200px) {
		padding-bottom: ${mobileTabBarHeight};

		&:has(#library-lnb) > footer {
			padding-left: ${libraryLnbDimensions.widthOnTablet + 40}px;
		}
	}
`

export const LayoutContainer: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
	const authStore = useAuthStore()
	const apiService = useApiService()
	useAuth()
	const [changedView, setChangedView] = useState(false)
	const router = useRouter()
	const { pathname, query } = router

	const { logoutCommand, isUseFooter } = useMemo(
		() => ({
			logoutCommand: new LogoutCommand(apiService),
			isUseFooter: !isInWebview(),
		}),
		[apiService],
	)

	const { postProcessForKakao } = useSignout()
	const onLogout = React.useCallback(async () => {
		if (authStore.user.providerId === 'kakao') {
			postProcessForKakao()
		}

		authStore.logout()
		await logoutCommand.execute()

		redirect(HOME_PAGE)
	}, [authStore, logoutCommand, postProcessForKakao])

	const withoutLayoutPages: string[] = [
		VIEWER_PRINTOUT_PAGE,
		VIEWER_PAGE,
		MYPAGE_STORES_PRODUCTS_NEW_INDEX,
		ADMIN_PAGE_LOGIN,
		PUBLISHER_LOGIN,
	]

	const isViewerPages = /\/viewer\//.test(pathname)
	const productEditPage = /\/products\/(.*)\/edit/.test(pathname)
	const goodNotesPage = pathname.includes(EBOOKS_UNIVERSITY_LANDING)
	const expertPage = pathname.includes('/expert')
	const renderGNB = useMemo(() => {
		if (goodNotesPage) return <GoodnotesGNB />
		if (expertPage) return <ExpertGNB />
		return <MarketGNB />
	}, [pathname])

	if (withoutLayoutPages.includes(pathname) || isViewerPages || productEditPage) {
		return <Content>{children}</Content>
	}
	return (
		<WebViewWrapper>
			{isUseFooter && renderGNB}
			<Content>{children}</Content>
			{isUseFooter && <FooterView />}
		</WebViewWrapper>
	)
})
