import { useRouter } from 'next/router'
import { useSideCategoryMenuStore } from '../../SideSheets/store/useCategoryMenuStore'
import { useCallback, useMemo } from 'react'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import useLoginRedirect from '@/hooks/useLoginRedirect'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { useQuery } from '@tanstack/react-query'
import { getLabByAuthorId } from '../../../api/lab'
import {
	EBOOKS_ORDER,
	LIBRARIES_INDEX_PAGE,
	LOGIN_PAGE,
	MYPAGE_COUPONS,
	MYPAGE_PAGE,
	MYPAGE_PASSES,
	MYPAGE_STORES_PRODUCTS_INDEX,
	REGISTRATION_PAGE,
	SHOP_CART_PAGE,
	SOLVOOK_WRITE_STUDIO,
} from '@/settings/constant'

type ButtonType =
	| 'cart'
	| 'expert'
	| 'library'
	| 'login'
	| 'signup'
	| 'mypage'
	| 'mypageCoupons'
	| 'mypagePasses'
	| 'mypageStores'
	| 'lab'
	| 'category'
	| 'menu'
	| null
const buttonType = {
	login: '로그인',
	signup: '회원가입',
	cart: '장바구니',
	expert: '엑스퍼트',
	library: '자료보관함',
	mypage: '마이쏠북',
	mypageCoupons: '쿠폰 관리',
	mypagePasses: '이용권 관리',
	mypageStores: '자료 판매',
	lab: '나의 연구소',
	category: '카테고리',
	menu: '메뉴',
	null: '',
} as const
const useGNBHandler = () => {
	const { push } = useRouter()
	const { redirectTo } = useLoginRedirect()
	const { user, isLoggedIn } = useAuthStore()
	const { isOpenSideMenu, openSideMenu, closeSideMenu, openMobileSideMenu } =
		useSideCategoryMenuStore()
	const findMixpanelButtonType = (url: string): ButtonType | '' => {
		if (url === SHOP_CART_PAGE || url === EBOOKS_ORDER) return 'cart'
		if (url === SOLVOOK_WRITE_STUDIO) return 'expert'
		if (url === LIBRARIES_INDEX_PAGE) return 'library'
		if (url === LOGIN_PAGE) return 'login'
		if (url === REGISTRATION_PAGE) return 'signup'
		if (url === MYPAGE_PAGE) return 'mypage'
		if (url === MYPAGE_COUPONS) return 'mypageCoupons'
		if (url === MYPAGE_PASSES) return 'mypagePasses'
		if (url === MYPAGE_STORES_PRODUCTS_INDEX) return 'mypageStores'
		if (url.includes('@')) return 'lab'
		return ''
	}
	const queueMixpanel = useCallback((type: string, name: string) => {
		mixpanelEvent('GNB Button Clicked', {
			'button type': type,
			'button name': name,
		})
	}, [])
	const handleDropdownClick = useCallback(
		(url: string) => {
			const type = findMixpanelButtonType(url)
			queueMixpanel('mypage', buttonType[type])
			push(url)
		},
		[user],
	)
	const handleCategoryButton = () => {
		if (isOpenSideMenu) closeSideMenu()
		else openSideMenu()
		queueMixpanel('category', '카테고리')
	}
	const handlePageMove = (url: string, redirect: boolean) => {
		const type = findMixpanelButtonType(url)
		queueMixpanel(type, buttonType[type])
		if (redirect) {
			redirectTo(url)
			return
		}
		push(url)
	}
	const handleSideMenu = () => {
		queueMixpanel('menu', buttonType.menu)
		openMobileSideMenu()
	}
	const { data: labDataResponse } = useQuery(
		['authorLab', user],
		() => getLabByAuthorId(user?.id),
		{
			enabled: isLoggedIn && !!user?.flags?.is_use_lab,
		},
	)
	const labName = useMemo(() => {
		if (!labDataResponse) return undefined
		return labDataResponse?.lab?.lab_name
	}, [labDataResponse])

	return {
		handleDropdownClick,
		handleCategoryButton,
		handlePageMove,
		isOpenSideMenu,
		handleSideMenu,
		labName,
		queueMixpanel,
	}
}
export default useGNBHandler
